<template>
  <div class="detailPage">
    <div class="detailContent">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
          <el-form-item label="用户昵称" prop="nickname">
            <el-input v-model="dataForm.nickname" placeholder="用户昵称"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="dataForm.phone" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input v-model="dataForm.password" placeholder="登录密码"></el-input>
          </el-form-item>
					<!-- <el-form-item label="选择角色" prop="roleId">
            <el-input v-model="dataForm.roleId" placeholder="选择角色"></el-input>
          </el-form-item> -->
					<el-form-item label="选择角色" prop="roleId">
						<el-select v-model="dataForm.roleId" placeholder="请选择角色" clearable>
							<el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
          <el-form-item class="buttons">
            <el-button @click="back()">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
export default {
	name: 'userAddOrUpdate',
	data () {
		return {
			dataForm: {
				id: 0,
				nickname: '',
				phone: '',
				password: '',
				createAt: ''
			},
			roleList: [],
			dataRule: {
				nickname: [
					{ required: true, message: '用户昵称不能为空', trigger: 'blur' }
				],
				phone: [
					{ required: true, message: '用户手机号不能为空', trigger: 'blur' }
				],
				password: [
					{ required: true, message: '用户密码不能为空', trigger: 'blur' }
				],
				roleId: [
					{ required: true, message: '用户角色不能为空', trigger: 'blur' }
				]
			},
			user: {},
			menuList: [],
			menuListTreeProps: {
				label: 'name',
				children: 'children'
			},
			checkType: [],
			editType: 1
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改用户';
			this.editType = 2;
		} else {
			this.$route.meta.navName = '新增用户';
			this.editType = 1;
		}
	},
	created () {
		this.getRoleList();
	},
	mounted () {
		const id = this.$route.query.id;
		if (id) {
			this.dataForm.id = id;
			// this.getDetail(id);
		}
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		getRoleList () {
			this.$root.request('roleList', {
				page: 1,
				limit: 100
			}).then((data) => {
				if (data) {
					this.roleList = data.rows.map((item, index) => {
						item.label = item.name;
						item.value = item.id;
						return item;
					});
				}
			});
		},
		// 表单提交
		dataFormSubmit () {
			this.$refs.dataForm.validate((valid) => {
				if (valid) {
					this.$root.request('userAdd', {
						...this.dataForm
					}).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.dataForm.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg('操作失败', 'error');
					});
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
	/deep/ .el-input {
		width: auto !important;
	}
</style>