var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailPage" }, [
    _c(
      "div",
      { staticClass: "detailContent" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.dataRule,
              "label-width": "80px"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.dataFormSubmit()
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "用户昵称", prop: "nickname" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "用户昵称" },
                  model: {
                    value: _vm.dataForm.nickname,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "nickname", $$v)
                    },
                    expression: "dataForm.nickname"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "手机号", prop: "phone" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "手机号" },
                  model: {
                    value: _vm.dataForm.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "phone", $$v)
                    },
                    expression: "dataForm.phone"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "登录密码", prop: "password" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "登录密码" },
                  model: {
                    value: _vm.dataForm.password,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "password", $$v)
                    },
                    expression: "dataForm.password"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "选择角色", prop: "roleId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择角色", clearable: "" },
                    model: {
                      value: _vm.dataForm.roleId,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "roleId", $$v)
                      },
                      expression: "dataForm.roleId"
                    }
                  },
                  _vm._l(_vm.roleList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.dataFormSubmit()
                      }
                    }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }